import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: {
    datoCmsPage: { seoMetaTags, title, bannerImage, modularBlocks },
  },
}) => {
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner title={title} image={bannerImage} />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        fluid(
          maxHeight: 380
          maxWidth: 1600
          imgixParams: { auto: "compress", fit: "crop", h: "380", w: "1600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      ...ModularBlocksFragment
    }
  }
`;

export default DefaultPageTemplate;
